<template>
  <div>
    <v-snackbar
      :top="y === 'top'"
      :timeout="timeout"
      :color="color"
      v-model="snackbar"
      >{{ snackbarMessage }}</v-snackbar
    >
    <v-container class="mt-10">
      <v-row>
        <div class="col-md-5">
          <h4 class="ael">Consultas o Comentarios</h4>

          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              v-model="nombre"
              :counter="50"
              label="Nombre"
              :rules="rules.nombre"
              required
            ></v-text-field>

            <v-text-field
              v-model="telefono"
              :counter="7"
              label="Telefono"
              :rules="rules.telefono"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              label="Email"
              :rules="rules.emailRules"
              required
            ></v-text-field>

            <v-textarea
              v-model="mensaje"
              name="input-7-1"
              label="Mensaje"
              :rules="rules.mensaje"
              required
            ></v-textarea>

            <v-btn dark class="mr-4 mt-5" @click="submit" color="#ffc53f">
              Aceptar
            </v-btn>

            <v-btn dark class="mt-5" @click="clear" color="red">
              Limpiar
            </v-btn>
          </v-form>
          <!-- </form> -->
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-5">
          <div class="datosdecontacto">
            <h4 class="ael pb-2">Datos de Contacto</h4>

            <div class="ael">
              <div class="pb-2">
                <v-icon color="black"> mdi-home </v-icon>

                -
              </div>

              <div class="pb-2">
                <v-icon color="black"> mdi-phone </v-icon>

                -
              </div>

              <div class="pb-2">
                <v-icon color="black"> mdi-cellphone </v-icon>

                -
              </div>

              <div class="pb-2">
                <v-icon color="black"> mdi-email </v-icon>

                -
              </div>
            </div>
          </div>

          <div class="rs mt-7">
            <h4 class="ael pb-2">Redes Sociales</h4>

            <div class="ael">
              <ul>
                <li>
                  <a href="#" title="Facebook">
                    <v-icon large color="black"> mdi-facebook </v-icon>
                  </a>

                  <a href="#" title="Twitter">
                    <v-icon large color="black"> mdi-twitter </v-icon>
                  </a>

                  <a href="#" title="Instagram">
                    <v-icon large color="black"> mdi-instagram </v-icon>
                  </a>

                  <a href="#" title="YouTube">
                    <v-icon large color="black"> mdi-youtube </v-icon>
                  </a>

                  <a href="#" title="LinkedIn">
                    <v-icon large color="black"> mdi-linkedin </v-icon>
                  </a>

                  <a href="#" title="Spotify">
                    <v-icon large color="black"> mdi-spotify </v-icon>
                  </a>

                  <a href="#" title="Discord">
                    <v-icon large color="black"> mdi-discord </v-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "../../api";
export default {
  name: "SobreNosotros",

  components: {
    // ValidationProvider,
    // ValidationObserver,
  },
  data: () => ({
    nombre: "",
    telefono: "",
    email: "",
    mensaje: "",
    valid: false,
    snackbar: false,
    color: "",
    snackbarMessage: "",
    timeout: 3000,
    y: "top",
    rules: {
      emailRules: [
        (v) => !!v || "Correo electónico es requerido",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Debe ser un correo electrónico válido",
      ],
      nombre: [(v) => !!v || "El nombre es requerido"],
      telefono: [(v) => !!v || "El teléfono es requerido"],
      mensaje: [(v) => !!v || "El mensaje es requerido"],
    },
  }),

  methods: {
    submit() {
      let mensajeContacto = {
        nombre: this.nombre,
        telefono: this.telefono,
        email: this.email,
        mensaje: this.mensaje,
      };
      if (this.$refs.form.validate()) {
        api
          .post("event/notificacion/contacto", mensajeContacto)
          .then((Response) => {
            this.snackbar = true;
            this.color = "#ffc53f";
            this.snackbarMessage = Response.data.message;
          })
          .catch((error) => {
            console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.snackbarMessage = error.response.data.message;
          });
      }
    },
    clear() {
      this.nombre = "";
      this.telefono = "";
      this.email = "";
      this.mensaje = "";
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.ael {
  text-align: left !important;
}

.rs > div > ul > li {
  display: inline;
}

.rs > div > ul {
  padding-left: initial;
}
</style>
