<template>
    <v-container class="mt-10">

        <div class="mt-6 mb-3 text-center">
            <h1>Nosotros</h1>
        </div>

        <v-row align="center" justify="center">

            <div class="col-md-5">

                <p class="text-justify">
                    Quisque faucibus sem at justo consectetur, a dictum ante rutrum. Proin in ipsum tempor, pharetra quam
                    vitae, tristique ex. Sed molestie eget tellus eu scelerisque. Ut pretium arcu ac augue egestas, ut
                    pellentesque lectus hendrerit. Vivamus interdum lorem sed facilisis volutpat. Donec varius arcu ac
                    condimentum elementum.
                    <br><br>
                    Quisque pretium hendrerit metus vel placerat. Aenean semper condimentum magna, a pharetra nisi imperdiet
                    non. Curabitur sodales, ligula quis semper pellentesque, mi tellus vestibulum nisi, id tincidunt nisi
                    est fringilla massa. Nunc bibendum mi vel tempor lobortis. Phasellus arcu dui, lacinia non bibendum
                    quis, aliquet sit amet enim. Vivamus dignissim facilisis aliquam. Etiam odio mauris, pulvinar in
                    porttitor at, pellentesque eu purus. Nam dapibus rhoncus justo et egestas. Sed sagittis leo a posuere
                    egestas. Sed in enim vitae velit scelerisque venenatis. Nam iaculis ut nisl vel finibus.
                </p>

            </div>

            <div class="col-md-7">

                <v-img src="img/img_nosotros.jpg"></v-img>

            </div>


        </v-row>

    </v-container>
</template>
<script>
export default {
    name: 'SobreNosotros',

}
</script>