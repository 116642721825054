
export function textTruncate(str, length, ending) {
    if(str == null) return ' ';
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = '...';
	}
	if (str.length > length) {
        console.log(str.substring(0, length - ending.length) + ending);
		return str.substring(0, length - ending.length) + ending;
	} else {
		return str;
	}
}

export function createObject(){
	const object = document.createElement('object');
	object.id = 'output';
	object.type = 'application/pdf';
	object.width = '100%';
	object.height = '600px';
	document.getElementById('mostrar').appendChild(object);
}

