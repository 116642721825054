<template>
  <v-carousel cycle v-model="model" height="416" class="mt-3" hide-delimiters>
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.img">
    </v-carousel-item>
  </v-carousel>
</template>
<script>
import api from "../../api";
export default {
  name: "Carousel",

  data() {
    return {
      items: [],
      posicion: 10,
      model: 0,
    };
  },
  mounted() {
    this.getImg();
  },
  methods: {
    getImg() {
      api.get("event/show/img/carrousel/" + this.posicion).then((response) => {
        // this.topImg = response.data.top;
        this.items = response.data.imagenes;

        console.log(response.data.imagenes);
      });
    },
  },
};
</script>
