<template>
  <v-container class="mt-10">
    <v-row align="center" justify="center">
      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s1.png" />
          <h2 class="mt-5">Servicio 1</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>

      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s2.png" />
          <h2 class="mt-5">Servicio 2</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>

      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s3.png" />
          <h2 class="mt-5">Servicio 3</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>
    </v-row>

    <v-row align="center" justify="center">
      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s4.png" />
          <h2 class="mt-5">Servicio 4</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>

      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s5.png" />
          <h2 class="mt-5">Servicio 5</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>

      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s6.png" />
          <h2 class="mt-5">Servicio 6</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>
    </v-row>

    <v-row align="center" justify="center">
      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s7.png" />
          <h2 class="mt-5">Servicio 7</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>

      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s8.png" />
          <h2 class="mt-5">Servicio 8</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>

      <div class="col-md-4">
        <div class="mt-6 mb-3 text-center">
          <img src="img/s9.png" />
          <h2 class="mt-5">Servicio 9</h2>
        </div>

        <p class="text-justify">
          Vestibulum eu vulputate lacus, eget dignissim velit. Sed odio enim,
          accumsan eu ligula sit amet, aliquet egestas elit. Donec auctor et
          quam ut dapibus. Donec a iaculis mauris. Donec molestie leo at ipsum
          eleifend consectetur. Phasellus quis lacus commodo, commodo ligula ac,
          volutpat tortor. Praesent imperdiet pellentesque feugiat.
        </p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GaleriaServicios",
};
</script>
