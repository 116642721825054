<template>
  <div>
    <v-snackbar
      :top="y === 'top'"
      :timeout="timeout"
      :color="color"
      v-model="snackbar"
      >{{ snackbarMessage }}</v-snackbar
    >
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="6">
          <v-form v-model="valid" class="5">
            <v-text-field
              label="Nueva Contraseña"
              v-model="editedItems.password"
              type="password"
              :rules="passwordRules"
              required
            ></v-text-field>
            <v-text-field
              label="Confirmar Contraseña"
              v-model="editedItems.password_confirmation"
              type="password"
              :rules="passwordRules"
              required
            ></v-text-field>
            <v-btn
              large
              block
              dark
              color="#ffc53f"
              class="mb-2 font-weight-bold"
              @click.prevent="resetPassword"
              >Guardar</v-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import api from "../../api";
export default {
  name: "nuevaClave",
  data() {
    return {
      email: this.$route.params.email,
      token: this.$route.params.token,
      valid: false,
      snackbar: false,
      color: "",
      snackbarMessage: "",
      timeout: 3000,
      y: "top",
      newPassword: "",
      confirmPassword: "",
      passwordRules: [(v) => !!v || "Password is required"],
      editedItems: {
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
      },
    };
  },

  methods: {
    resetPassword() {
      this.editedItems.email = this.email;
      this.editedItems.token = this.token;
      api.post("password/reset", this.editedItems).then((response) => {
        this.snackbar = true;
        this.color = "primary";
        this.snackbarMessage = response.data.message;
        console.log(response.data);
        this.$router.push("/micuenta");
      });
    },
  },
};
</script>
