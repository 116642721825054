<template>
  <div>
    <v-snackbar
      :top="y === 'top'"
      :timeout="timeout"
      :color="color"
      v-model="snackbar"
      >{{ snackbarMessage }}</v-snackbar
    >
    <v-container fluid>
      <!-- <v-card> -->
      <!-- <v-breadcrumbs
        class="breadcrumbs"
        :items="[
          {
            text: 'Home',
            disabled: false,
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Link 1',
            disabled: false,
            href: 'breadcrumbs_link_1',
          },
          {
            text: 'Link 2',
            disabled: false,
            href: 'breadcrumbs_link_2',
          },
        ]"
      > -->
      <!-- <template v-slot:divider>
          <v-icon size="25">mdi-forward</v-icon>
        </template> -->
      <!-- <template v-slot:item="{ item }">
          <v-breadcrumbs-item>
            <span :style="`color: ${item.color}`">{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template> -->
      <!-- </v-breadcrumbs> -->
      <!-- </v-card> -->
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card class="mx-4 ma-4">
            <v-card-title class="font-weight-bold text-h5">
              Acceder a la Cuenta
            </v-card-title>
            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="E-mail *"
                      :rules="emailRules"
                      v-model="editedItems.email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Password *"
                      :rules="passwordRules"
                      v-model="editedItems.password"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      large
                      block
                      color="#ffc53f"
                      class="mb-2 font-weight-bold"
                      dark
                      @click.prevent="login"
                      >Iniciar Sesión</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      large
                      block
                      color="#13597c"
                      class="mb-2 font-weight-bold"
                      dark
                      to="/recuperarclave"
                      >¿Olvido su contraseña?</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card class="mx-4 ma-4">
            <v-card-title class="font-weight-bold text-h5">
              Registrarse
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-card-text>
                  <!-- <v-row no-gutters>
                    <v-col cols="12" sm="12" md="8">
                      <v-select label="Inscripciones" required></v-select>
                    </v-col>
                  </v-row> 
                  <v-divider class="mx-auto"></v-divider> -->
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Nombre *"
                        v-model="registroItems.nombre"
                        :rules="rules.nombre"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Apellido *"
                        v-model="registroItems.apellido"
                        :rules="rules.apellido"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="DUI *"
                        v-model="registroItems.dui"
                        :rules="rules.dui"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Teléfono *"
                        v-model="registroItems.telefono"
                        :rules="rules.telefono"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="5">
                      <v-autocomplete
                        v-model="selectSexo"
                        label="Sexo"
                        :items="sex"
                        item-text="tipo"
                        return-object
                        :rules="rules.sexo"
                        required
                      ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Dirección *"
                        v-model="registroItems.direccion"
                        :rules="rules.direccion"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Ciudad *"
                        v-model="registroItems.ciudad"
                        :rules="rules.ciudad"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="5">
                      <v-autocomplete
                        v-model="selectDepto"
                        label="Departamento *"
                        :items="deptos"
                        item-text="nombre_departamento"
                        return-object
                        :rules="rules.departamento"
                        required
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Fecha de Nacimiento *"
                        v-model="registroItems.fecha_nacimiento"
                        type="date"
                        :rules="rules.fecha_nacimiento"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        label="Correo Electrónico *"
                        v-model="registroItems.email"
                        :rules="rules.email"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        v-model="registroItems.password"
                        label="Contraseña *"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" md="5">
                      <v-text-field
                        v-model="registroItems.password_confirmation"
                        label="Repetir contraseña *"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div class="layout justify-end">
                    <v-btn
                      large
                      block
                      color="#ffc53f"
                      class="mb-2 font-weight-bold"
                      dark
                      @click.prevent="save()"
                      :disabled="!valid"
                      :loading="loading"
                      >Registrarse
                      <template v-slot:loader>
                        <span>Guardando...</span>
                      </template>
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import api from "../../api/index.js";
import router from "../../router";
import data from "./data";
export default {
  data() {
    return {
      valid: false,
      loading: false,
      snackbar: false,
      color: "",
      snackbarMessage: "",
      timeout: 3000,
      y: "top",
      sex: data,
      selectSexo: "",
      selectDepto: "",
      deptos: [],
      editedItems: {
        id: "",
        email: "",
        password: "",
      },
      registroItems: {
        id: "",
        nombre: "",
        apellido: "",
        dui: "",
        telefono: "",
        sexo: "",
        direccion: "",
        ciudad: "",
        departamento_id: "",
        fecha_nacimiento: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      registroDefaultItems: {
        id: "",
        nombre: "",
        apellido: "",
        dui: "",
        telefono: "",
        sexo: "",
        direccion: "",
        ciudad: "",
        departamento_id: "",
        fecha_nacimiento: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      emailRules: [
        (v) => !!v || "El E-mail es requerido",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Debe ser un E-mail válido",
      ],
      passwordRules: [(v) => !!v || "Debe escribir una contraseña"],
      rules: {
        nombre: [(v) => !!v || "El campo nombre es requerido"],
        apellido: [(v) => !!v || "El campo apellido es obligatorio"],
        dui: [(v) => !!v || "El campo dui es obligatorio"],
        telefono: [(v) => !!v || "El campo telefono es obligatorio"],
        sexo: [(v) => !!v || "El campo sexo es obligatorio"],
        inscripcion: [(v) => !!v || "El campo inscripción es obligatorio"],
        direccion: [(v) => !!v || "El campo direccion es obligatorio"],
        ciudad: [(v) => !!v || "El campo ciudad es obligatorio"],
        departamento: [(v) => !!v || "El campo departamento es obligatorio"],
        fecha_nacimiento: [
          (v) => !!v || "El campo fecha nacimiento es obligatorio",
        ],
        email: [(v) => !!v || "El campo correo electrónico es obligatorio"],
      },
    };
  },
  mounted() {
    this.getDepartamentos();
  },
  methods: {
    login() {
      api
        .post("cliente/login", this.editedItems)
        .then((response) => {
          localStorage.setItem("auth_token", response.data.access_token);
          api.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("auth_token");
          router.push("/");

          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.snackbarMessage = error.response.data.message;
          // this.snackMessage("error", error.response.data.message);
        });
    },
    getDepartamentos() {
      api.get("event/show/deptos").then((response) => {
        this.deptos = response.data.data;
        // console.log(response.data.data);
      });
    },
    save() {
      this.registroItems.departamento_id = this.selectDepto.id;
      this.registroItems.sexo = this.selectSexo.id;
      // this.registroItems.evento_id = this.data.id;
      // this.registroItems.inscripcion_id = this.selectInscripcion.id;

      if (this.$refs.form.validate()) {
        this.loading = true;
        api
          .post("event/signup/cliente", this.registroItems)
          .then((response) => {
            // console.log(response.data);
            // let cliente_id = response.data.id;
            this.snackbar = true;
            this.color = "primary";
            this.snackbarMessage = response.data.message;
            this.$refs.form.resetValidation();
            this.registroItems = Object.assign({}, this.registroDefaultItems);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.snackbarMessage = error.response.data.message;
            this.loading = false;
          });
      }
      // console.log(this.editedItem);
    },
  },
};
</script>
<style scope>
.routerlink {
  display: block;
  color: rgb(240, 20, 20);
}

.v-breadcrumbs:deep(a) {
  color: purple;
}
</style>
