<template>
  <v-menu
    offset-y
    origin="right top"
    left
    content-class="language-dropdown"
    transition="slide-y-transition"
    nudge-top="-10"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon large v-on="on" class="user-block-wrap ml-3">
        <v-avatar color="#cc9d32">
          <span class="text-h5">{{ obtenerIniciales() }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <div class="dropdown-content">
      <div class="dropdown-top white--text primary">
        <span class="white--text fs-14 fw-bold d-block">{{ full_name }}</span>
        <!-- <span class="d-block fs-12 fw-normal">info@example.com</span> -->
      </div>
      <v-list class="dropdown-list" v-if="full_name">
        <v-list-item @click="logoutUser()">
          <i class="ti-power-off mr-3 error--text"></i
          ><span>Cerrar Sesion</span>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>
<script>
import api from "../api";
// import router from "../router";
export default {
  name: "avatar",
  data() {
    return {
      full_name: localStorage.getItem("full_name"),
    };
  },
  mounted() {
    this.obtenerIniciales();
  },
  methods: {
    logoutUser() {
      api
        .get("cliente/logout")
        .then((response) => {
          console.log(response.data.message);
          this.$router.push("/micuenta");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerIniciales() {
      const partesNombres = this.full_name.split(" ");
      let iniciales = "";
      for (let i = 0; i < partesNombres.length; i++) {
        iniciales += partesNombres[i].charAt(0).toUpperCase();
      }
      return iniciales;
      // console.log(iniciales);
    },
  },
};
</script>
