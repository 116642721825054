export default [
    {
        id:1,
        talla:'XS Femenino'
    },
    {
        id:2,
        talla:'S Femenino'
    },
    {
        id:3,
        talla:'M Femenino'
    },
    {
        id:4,
        talla:'L Femenino'
    },
    {
        id:5,
        talla:'XL Femenino'
    },
    {
        id:6,
        talla:'XXL Femenino'
    },
    {
        id:7,
        talla:'XS Masculino'
    },
    {
        id:8,
        talla:'S Masculino'
    },
    {
        id:9,
        talla:'M Masculino'
    },
    {
        id:10,
        talla:'L Masculino'
    },
    {
        id:11,
        talla:'XL Masculino'
    },
    {
        id:12,
        talla:'XXL Masculino'
    },
]