<template dark>
  <v-card dark class="pt-5 mt-10" color="#13597c">
    <v-container>
      <div class="text-center">
        <p><span id="año"></span>. Todos los Derechos Reservados.</p>
      </div>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "Footer",

  methods: {
    // Imprimo el año actual
    obtenerAño() {
      var año = new Date().getFullYear();
      document.getElementById("año").innerHTML = año;
      //console.log(año);
    },
  },

  mounted() {
    this.obtenerAño();
  },
};
</script>
