<template>
  <v-item-group class="mt-6">
    <v-container>
      <v-row>
        <!-- Servicio 1 -->
        <template v-for="(item, i) in items">
          <v-hover v-slot="{ hover }" :key="i">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-4 ma-10"
              max-width="340"
              height="450"
              link
              @click="irA(item.slug)"
            >
              <v-img
                :src="item.url_imagen + validaPath(item.image)"
                height="339"
                width="400"
                aspect-ratio="8"
                cover
              >
              </v-img>
              <v-card-title class="justify-center">
                {{ item.nombre_evento }}
              </v-card-title>

              <!-- <v-card-subtitle
              class="alinearjustificado"
              v-if="item.mostrar_descripcion_evento"
            >
              <p v-html="textTruncate(item.descripcion_evento)"></p> -->
              <!-- <p v-html="item.descripcion_evento"></p> -->
              <!-- </v-card-subtitle> -->
              <!-- <v-card-text class="my-4 text-center text-h4">
                {{ item.nombre_evento }}</v-card-text
              > -->
              <!-- <v-card-subtitle>
                {{ parseDate(item.fecha_hora_inicio) }}
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="orange" variant="text"> Ver mas... </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-hover>
        </template>
      </v-row>
      <!-- <v-fab-transition>
        <a
          href="https://api.whatsapp.com/send?phone=+50378600587&text=Deseo Información"
        >
          <v-btn color="green" small fab dark absolute bottom right>
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </a>
      </v-fab-transition> -->
    </v-container>
  </v-item-group>
</template>
<script>
import api from "../../api";
import { textTruncate } from "./../../helpers/helpers";
import router from "../../router";
import moment from "moment";

export default {
  name: "Servicios",
  data() {
    return {
      items: [],
      htmlTex: "",
    };
  },
  mounted() {
    // this.irA();
    this.pruebaApi();
  },
  methods: {
    pruebaApi() {
      api
        .get("event/show/evento/publico")
        .then((Response) => {
          this.items = Response.data.data;
          //console.log(this.items);
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    textTruncate(text) {
      return textTruncate(text, 300);
    },
    irA(e) {
      router.push("/mostrarinfo/" + e);
      // router.push("/mostrarinfo/el-espino-run");
    },
    parseDate(fecha) {
      moment.locale("es");
      let fecha_final = this.separa_fecha(fecha);
      return moment(fecha_final).format("D [de] MMMM [de] YYYY");
    },
    separa_fecha(fecha_hora) {
      if (fecha_hora == null) return;

      const fechaSeparada = fecha_hora.split("T");
      const fechaFinal = fechaSeparada[0];

      return fechaFinal;
    },

    validaPath(path) {
      if (path.length > 0) {
        const encontrarPathEnPosicion2 = path.find(
          (item) => item.posicion === 2
        ).path;
        return encontrarPathEnPosicion2;
      }
      return "no_image.png";
    },
    validaFechainicio(fecha_hora_inicio) {
      let fechaActual = moment();
      let result = fechaActual.isAfter(fecha_hora_inicio);
      //console.log(result);
      return result;
    },
    irW() {
      router.push("https://www.google.com/");
    },
    /**TODO: Controlar si inicia o finaliza el periodo de inscripcion */
    // inicioInscripcion(fecha_hora_inicio) {},
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
