<template>
  <v-app>
    <v-main>
      <Menu />

      <!-- <Carousel /> -->
      <ConsultaPago />
      <!-- <ServiciosDestacados /> -->
      <!-- <Newsletter /> -->

      <router-view>
        <v-content> </v-content>
      </router-view>

      <Footer />
    </v-main>
  </v-app>
</template>
<script>
import Menu from "./Menu";
// import Carousel from "./Home/Carousel";
import ConsultaPago from "./MostrarInfo/ConsultaPago";
import Footer from "./Footer";

export default {
  name: "MostrarPago",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Inscribeteenlinea.com";
      },
    },
  },
  components: {
    Menu,
    // Carousel,
    ConsultaPago,
    // ServiciosDestacados,
    // Newsletter,
    Footer,
  },
};
</script>
