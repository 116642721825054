<template>
    <v-container class="mt-10">

        <div class="mt-6 mb-3 text-center">
            <h1> Servicios </h1>
        </div>

        <v-row align="center" justify="center">

            <div class="col-md-12">

                <p class="text-justify">
                    Etiam varius, quam at accumsan laoreet, ligula ante luctus tellus, at euismod justo urna non arcu.
                    Maecenas dignissim nec justo eget varius. Suspendisse ullamcorper sapien non pulvinar tincidunt.
                    Phasellus venenatis, lacus ac semper aliquet, metus arcu feugiat mauris, id feugiat risus urna ut orci.
                    Curabitur blandit lacus sit amet eleifend mattis. Fusce odio orci, lacinia a vulputate ac, sollicitudin
                    nec justo. Nulla pretium condimentum diam nec luctus. Morbi vehicula semper dignissim. Quisque imperdiet
                    felis vitae feugiat sagittis. Pellentesque magna nulla, cursus vitae semper sed, ornare sit amet odio.
                    Nulla in nisi maximus, feugiat justo sit amet, venenatis lectus. Aliquam erat volutpat. Nunc et tellus
                    in libero cursus vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                    ultricies velit eget orci interdum, sit amet aliquet mi imperdiet.
                    <br><br>
                    A continuación todos nuestros servicios:
                </p>

            </div>


        </v-row>

    </v-container>
</template>
 
<script>
export default {
    name: 'IntroduccionServicios',

}
</script>