<template>
  <div>
    <v-snackbar
      :top="y === 'top'"
      :timeout="timeout"
      :color="color"
      v-model="snackbar"
      >{{ snackbarMessage }}</v-snackbar
    >
    <v-container>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="editemItem.email"
              append-icon="mdi-email"
              label="Correo Electrónico"
              single-line
              hide-details
              :rules="rules.emailRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="6">
            <v-btn
              @click.prevent="restablecerContrasena()"
              large
              block
              color="#ffc53f"
              class="mb-2 font-weight-bold"
              dark
              >Restablecer Contraseña</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import api from "../../api";
export default {
  name: "recuperarContrasena",
  data() {
    return {
      error: this.$route.params.error,
      valid: false,
      snackbar: false,
      color: "",
      snackbarMessage: "",
      timeout: 3000,
      y: "top",
      tokenNoValido: 1,
      tokenNoEncontrado: 2,
      usuarioNoEncontrado: 3,
      editemItem: {
        email: "",
      },
      rules: {
        emailRules: [
          (v) => !!v || "Correo electónico es requerido",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "Debe ser un correo electrónico válido",
        ],
      },
    };
  },
  mounted() {
    if (this.error == this.tokenNoValido) {
      this.snackbar = true;
      this.color = "error";
      this.snackbarMessage = "El token ha expirado";
    } else if (this.error == this.tokenNoEncontrado) {
      this.snackbar = true;
      this.color = "error";
      this.snackbarMessage = "El token no es válido";
    } else if (this.error == this.usuarioNoEncontrado) {
      this.snackbar = true;
      this.color = "error";
      this.snackbarMessage =
        "No ha sido posible encontrar el usuario con esa direccion de email";
    }
  },
  methods: {
    restablecerContrasena() {
      if (this.$refs.form.validate()) {
        api
          .post("password/create", this.editemItem)
          .then((Response) => {
            console.log(Response.data);
            this.snackbar = true;
            this.color = "primary";
            this.snackbarMessage = Response.data.message;
            this.reset();
          })
          .catch((error) => {
            console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.snackbarMessage = error.response.data.message;
            this.loading = false;
          });
      }
    },
    reset() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.form.resetValidation();
    },
  },
};
</script>
