<template>
    <v-container class="mt-10">

        <v-row align="center" justify="center">

            <div class="col-md-4">

                <div class="mt-6 mb-3 text-center">
                    <img src="img/mision.png" />
                    <h2 class="mt-5">Misión</h2>
                </div>

                <p class="text-justify">
                    Quisque faucibus sem at justo consectetur, a dictum ante rutrum. Proin in ipsum tempor, pharetra quam
                    vitae, tristique ex. Sed molestie eget tellus eu scelerisque. Ut pretium arcu ac augue egestas, ut
                    pellentesque lectus hendrerit. Vivamus interdum lorem sed facilisis volutpat. Donec varius arcu ac
                    condimentum elementum.
                </p>

            </div>

            <div class="col-md-4">

                <div class="mt-6 mb-3 text-center">
                    <img src="img/objetivo.png" />
                    <h2 class="mt-5">Objetivo</h2>
                </div>

                <p class="text-justify">
                    Quisque faucibus sem at justo consectetur, a dictum ante rutrum. Proin in ipsum tempor, pharetra quam
                    vitae, tristique ex. Sed molestie eget tellus eu scelerisque. Ut pretium arcu ac augue egestas, ut
                    pellentesque lectus hendrerit. Vivamus interdum lorem sed facilisis volutpat. Donec varius arcu ac
                    condimentum elementum.
                </p>

            </div>

            <div class="col-md-4">

                <div class="mt-6 mb-3 text-center">
                    <img src="img/vision.png" />
                    <h2 class="mt-5">Visión</h2>
                </div>

                <p class="text-justify">
                    Quisque faucibus sem at justo consectetur, a dictum ante rutrum. Proin in ipsum tempor, pharetra quam
                    vitae, tristique ex. Sed molestie eget tellus eu scelerisque. Ut pretium arcu ac augue egestas, ut
                    pellentesque lectus hendrerit. Vivamus interdum lorem sed facilisis volutpat. Donec varius arcu ac
                    condimentum elementum.
                </p>

            </div>


        </v-row>

    </v-container>
</template>
 
<script>
export default {
    name: 'InformacionAdicional',

}
</script>