<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12">
          <v-card
            class="mx-auto ma-4 my-12"
            max-width="374"
            text-left
            height="auto"
          >
            <v-card-title>{{ nombre_evento }}</v-card-title>
            <v-card-text>
              <div class="my-4 text-subtitle-1">
                <span>Nombre Cliente: {{ nombre_cliente }}</span>
              </div>
              <div class="my-4 text-subtitle-1">
                <span>Categoría: {{ descripcion_inscripcion }}</span>
              </div>
              <div class="my-4 text-subtitle-1">
                <span>Valor de inscripcion pagado:{{ valor_pagado }}</span>
              </div>
              <div class="my-4 text-subtitle-1">
                <span>Esta pagado:{{ esta_pagado }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import api from "../../api";
export default {
  name: "ConsultaPago",
  data() {
    return {
      cliente_id: this.$route.params.cliente_id,
      evento_id: this.$route.params.evento_id,
      nombre_cliente: "",
      nombre_evento: "",
      descripcion_inscripcion: "",
      valor_pagado: "",
      esta_pagado: "",
    };
  },
  mounted() {
    this.obtenerInfo();
  },
  methods: {
    obtenerInfo() {
      api
        .get(
          "event/inscripcion/evento/" + this.cliente_id + "/" + this.evento_id
        )
        .then((Response) => {
          this.nombre_evento = Response.data.data.evento.nombre_evento;
          this.nombre_cliente = Response.data.data.cliente.full_name;
          this.descripcion_inscripcion =
            Response.data.data.inscripciones.descripcion_etiqueta;
          this.valor_pagado = Response.data.data.valor_pagado;
          this.esta_pagado = Response.data.data.pagado == 1 ? "Si" : "No";
        });
    },
  },
};
</script>
