<template>
  <v-app app>
    <v-main>
      <Menu :key="user" />
      <!-- <nav-bar /> -->

      <Carousel />
      <ServiciosDestacados />
      <!-- <Newsletter /> -->

      <router-view>
        <v-content> </v-content>
      </router-view>

      <Footer />
    </v-main>
  </v-app>
</template>
<script>
import Menu from "./Menu";
// import NavBar from "./NavBar";
import Carousel from "./Home/Carousel";
import ServiciosDestacados from "./Home/ServiciosDestacados";
// import Newsletter from './Home/Newsletter';
import Footer from "./Footer";
// import NavBar from "./NavBar.vue";
import api from "../api";
export default {
  name: "Home",

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Inscribeteenlinea.com";
      },
    },
  },

  components: {
    Menu,
    // NavBar,
    Carousel,
    ServiciosDestacados,
    // Newsletter,
    Footer,
    // NavBar,
  },
  data() {
    return {
      user: "",
    };
  },
  mounted() {
    api.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("auth_token");
    this.getCurrentUser();
  },
  methods: {
    getCurrentUser() {
      api
        .get("cliente/user")
        .then((response) => {
          let full_name = response.data.data.full_name;
          let id = response.data.data.id;
          localStorage.setItem("id", id);
          localStorage.setItem("full_name", full_name);
          this.user = id;
          // console.log(response.data.data);
        })
        .catch((error) => {
          localStorage.clear();
          // router.push("/session/login");
          console.log(error.message);
        });
    },
  },
};
</script>
