<template>
  <div>
    <v-snackbar
      :top="y === 'top'"
      :timeout="timeout"
      :color="color"
      v-model="snackbar"
      >{{ snackbarMessage }}</v-snackbar
    >
    <v-container fluid>
      <v-row align="start" no-gutters>
        <v-col cols="12" sm="12" md="12">
          <v-card class="mx-auto" height="100" height-max="100" flat>
            <v-card-title class="my-4 text-left text-h4" color="black"
              >{{ data.nombre_evento }}
            </v-card-title>
            <!--<v-img :src="topImg" height="200px" height-max="300" cover> -->
            <v-card-subtitle class="blue--text text-left font-weight-light">
              <div class="blue--text">
                <v-icon dark small>mdi-calendar</v-icon>
                {{ parseDate(data.fecha_hora_inicio) }}
              </div>
              <div class="blue--text" v-if="data.email_contacto != null">
                <v-icon dark small>mdi-email</v-icon>
                {{ data.email_contacto }}
              </div>

              <div class="blue--text" v-if="data.enlace_facebook != null">
                <v-icon dark small>mdi-facebook</v-icon>
                {{ data.enlace_facebook }}
              </div>
              <!-- <div class="blue--text" v-if="data.enlace_facebook != null">
                <v-icon dark small>mdi-facebook</v-icon>
                {{ data.enlace_facebook }}
              </div> -->
              <!-- <div class="blue--text" v-if="data.enlace_twitter != null">
                <v-icon dark small>mdi-twitter</v-icon>
                {{ data.enlace_twitter }}
              </div> -->
              <!-- </v-img> -->
            </v-card-subtitle>
          </v-card>
          <!-- <v-sheet class="pa-2 ma-2"> .align-self-start </v-sheet> -->
        </v-col>
        <!-- <v-col cols="12" sm="12" md="12">
          <div class="blue--text" v-if="data.enlace_web_alternativa != null">
            <v-icon dark small>mdi-link</v-icon>
            <a class="blue--text" :href="data.enlace_web_alternativa">{{
              data.enlace_web_alternativa
            }}</a>
          </div>
          <div class="blue--text" v-if="data.enlace_twitter != null">
            <v-icon dark small>mdi-twitter</v-icon>
            {{ data.enlace_twitter }}
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="5"
          ><v-card class="mx-4 ma-4" dark flat>
            <!-- <v-img :src="leftImg" max-height="90vh"> </v-img> -->
            <v-carousel cycle :show-arrows="true">
              <v-carousel-item
                v-for="(img, i) in leftImg"
                :key="i"
                :src="img"
                cover
              ></v-carousel-item>
            </v-carousel> </v-card
        ></v-col>
        <v-col cols="12" sm="12" md="7">
          <v-row no-gutters>
            <v-col cols="3" sm="3" md="3" v-if="data.mostrar_limite_publico"
              ><v-card
                class="mx-4 ma-4 pa-2 font-weight-light"
                height="auto"
                color="#13597c"
                dark
                hover
                flat
              >
                <p>Límite</p>
                <span>{{ data.limite_inscripcion }}</span>
              </v-card></v-col
            >
            <v-col cols="3" sm="3" md="3" v-if="data.mostrar_inscritos_publico"
              ><v-card
                class="mx-4 ma-4 pa-2 font-weight-light"
                height="auto"
                color="#13597c"
                dark
                hover
                flat
              >
                <!-- TODO: Realizar calculos -->
                <p>Quedan</p>
                <span>{{
                  data.limite_inscripcion - totalInscritos
                }}</span></v-card
              ></v-col
            >
            <v-col
              cols="3"
              sm="3"
              md="3"
              v-if="data.mostrar_limite_restante_publico"
              ><v-card
                class="mx-4 ma-4 pa-2 font-weight-light"
                height="auto"
                color="#13597c"
                dark
                hover
                flat
              >
                <!-- TODO: Realizar calculos -->
                <p>Ya Somos</p>
                <span>{{ totalInscritos }}</span>
              </v-card></v-col
            >
            <v-col
              cols="3"
              sm="3"
              md="3"
              v-if="data.mostrar_numero_inscripcion_publico"
              ><v-card
                class="mx-4 ma-4 pa-2 font-weight-light"
                height="auto"
                color="#13597c"
                dark
                hover
                flat
              >
                <p>Listado</p>
                <span><v-icon dark>mdi-format-list-bulleted</v-icon></span>
              </v-card></v-col
            >
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" v-if="data.mostrar_limite_publico">
              <v-progress-linear
                color="light-blue darken-4"
                buffer-value="0"
                :value="totalBarra()"
                stream
              ></v-progress-linear>
            </v-col>
            <!-- FIXME: cambiar  -->
            <v-col cols="12" sm="12" md="12">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6" sm="12">
                    <v-select
                      v-model="selectInscripcion"
                      label="Inscripciones"
                      :items="Inscripcion"
                      item-text="inscripcion_precio"
                      return-object
                      :rules="rules.inscripcion"
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    v-if="selectInscripcion.precio > 0"
                  >
                    <v-select
                      v-model="selectTalla"
                      label="Talla de Camisa"
                      :items="talla"
                      item-text="talla"
                      return-object
                      :rules="rules.talla"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="data.mostrar_descripcion_evento"
            >
              <v-card class="mx-auto ma-4" text-left height="auto" flat>
                <v-card-text class="mx-auto pa-8">
                  <span v-html="data.descripcion_evento"></span>
                </v-card-text>
              </v-card>
              <v-card-actions
                v-if="validarInicioEvento() && !validarFinEvento()"
              >
                <v-btn
                  color="#13597c"
                  @click="save"
                  dark
                  v-if="id && !pagado"
                  :disabled="!valid"
                  :loading="loading"
                >
                  Inscribirme
                  <template v-slot:loader>
                    <span>Guardando...</span>
                  </template>
                </v-btn>
                <v-btn color="#13597c" @click="irAInicioSesion" dark v-if="!id">
                  Iniciar Sesión
                </v-btn>
                <v-btn color="#13597c" dark v-if="pagado && id">
                  Ya esta Inscrito
                </v-btn>
              </v-card-actions>
              <v-card-actions
                class="blue--text text-left font-weight-light"
                v-if="validarFinEvento()"
              >
                <div>
                  Inscripciones Cerradas
                  <!-- <br />
                  <p>
                    Te esperamos en la meta en El Espino Run el próximo 7 de
                    abril 🏃🏻‍♀🏃🏻‍♂☀🌴
                  </p> -->
                </div>
              </v-card-actions>
              <v-card-actions
                class="blue--text text-left font-weight-light"
                v-if="!validarInicioEvento() && !validarFinEvento()"
              >
                <div>Proximamente Inicio de Inscripciones</div>
              </v-card-actions>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-card flat>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="8">
                        <v-select
                          v-model="selectInscripcion"
                          label="Inscripciones"
                          :items="Inscripcion"
                          item-text="inscripcion_precio"
                          return-object
                          :rules="rules.inscripcion"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-divider class="mx-auto"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Nombre *"
                          v-model="editedItem.nombre"
                          :rules="rules.nombre"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Apellido *"
                          v-model="editedItem.apellido"
                          :rules="rules.apellido"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="DUI *"
                          v-model="editedItem.dui"
                          :rules="rules.dui"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Teléfono *"
                          v-model="editedItem.telefono"
                          :rules="rules.telefono"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="5">
                        <v-autocomplete
                          v-model="selectSexo"
                          label="Sexo"
                          :items="sex"
                          item-text="tipo"
                          return-object
                          :rules="rules.sexo"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Dirección *"
                          v-model="editedItem.direccion"
                          :rules="rules.direccion"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Ciudad *"
                          v-model="editedItem.ciudad"
                          :rules="rules.ciudad"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="12" md="5">
                        <v-autocomplete
                          v-model="selectDepto"
                          label="Departamento *"
                          :items="deptos"
                          item-text="nombre_departamento"
                          return-object
                          :rules="rules.departamento"
                          required
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Fecha de Nacimiento *"
                          v-model="editedItem.fecha_nacimiento"
                          type="date"
                          :rules="rules.fecha_nacimiento"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          label="Correo Electrónico *"
                          v-model="editedItem.email"
                          :rules="rules.email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     --inivia comentario-- <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <span @click.prevent="terminos()">
                          Consulta los términos de responsabilidad y declaración
                          de aptitud Física (aquí)
                        </span>

                        <v-checkbox label="Acepto"></v-checkbox>
                      </v-col>--termina comentario--
                    </v-row> 
                  </v-card-text>
                  <v-card-actions>
                    <div class="layout justify-end">
                      <v-btn
                        color="#13597c"
                        dark
                        @click.prevent="save()"
                        :disabled="!valid"
                        :loading="loading"
                        >Registrarme
                        <template v-slot:loader>
                          <span>Guardando...</span>
                        </template>
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-form>
                <div class="layout justify-end">
                  <span>* Indica campo obrigatorio</span>
                </div>
              </v-card>
            </v-col>
          </v-row> -->
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-toolbar color="primary" dark
            ><v-btn icon @click.prevent="close()"
              ><v-icon>mdi-close</v-icon></v-btn
            >

            <v-toolbar-title
              >Términos de responsabilidad y declaración de
              aptitud</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <div id="mostrar" name="mostrar">texto...</div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- <div id="mostrar" name="mostrar">texto...</div> -->
  </div>
</template>
<script>
import api from "../../api";
import moment from "moment";
import router from "../../router";
import data from "./data";
import talla from "./data-1";
import { createObject } from "../../helpers/helpers";
export default {
  name: "InforDetail",
  data() {
    return {
      slug: this.$route.params.slug,
      dialog: false,
      snackbar: false,
      color: "",
      snackbarMessage: "",
      timeout: 3000,
      y: "top",
      loading: false,
      data: [],
      topImg: "",
      leftImg: "",
      valid: false,
      deptos: [],
      sex: data,
      selectSexo: "",
      selectDepto: "",
      selectInscripcion: "",
      selectTalla: "",
      Inscripcion: [],
      pagado: 0,
      talla: talla,
      id: localStorage.getItem("id"),
      createObject: createObject,
      totalInscritos: "",
      editedItem: {
        id: "",
        nombre: "",
        apellido: "",
        dui: "",
        telefono: "",
        sexo: "",
        direccion: "",
        ciudad: "",
        departamento_id: "",
        fecha_nacimiento: "",
        email: "",
        password: "",
        pagado: 0,
        evento_id: "",
        inscripcion_id: "",
        talla: "",
      },
      defaultItem: {
        id: "",
        nombre: "",
        apellido: "",
        dui: "",
        telefono: "",
        sexo: "",
        direccion: "",
        ciudad: "",
        departamento_id: "",
        fecha_nacimiento: "",
        email: "",
        password: "",
        pagado: 0,
        evento_id: "",
        inscripcion_id: "",
        talla: "",
      },
      rules: {
        nombre: [(v) => !!v || "El campo nombre es requerido"],
        apellido: [(v) => !!v || "El campo apellido es obligatorio"],
        dui: [(v) => !!v || "El campo dui es obligatorio"],
        telefono: [(v) => !!v || "El campo telefono es obligatorio"],
        sexo: [(v) => !!v || "El campo sexo es obligatorio"],
        inscripcion: [(v) => !!v || "El campo inscripción es obligatorio"],
        talla: [(v) => !!v || "El campo inscripción es obligatorio"],
        direccion: [(v) => !!v || "El campo direccion es obligatorio"],
        ciudad: [(v) => !!v || "El campo ciudad es obligatorio"],
        departamento: [(v) => !!v || "El campo departamento es obligatorio"],
        fecha_nacimiento: [
          (v) => !!v || "El campo fecha nacimiento es obligatorio",
        ],
        email: [(v) => !!v || "El campo correo electrónico es obligatorio"],
      },
    };
  },
  mounted() {
    api.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("auth_token");
    this.mostrarinfo();

    // this.getDepartamentos();
  },
  methods: {
    mostrarinfo() {
      api.get("event/show/evento/slug/" + this.slug).then((Response) => {
        this.data = Response.data.data;
        // console.log(this.data);
        this.getImg();
        this.getTipoInscripcion();
        this.verificarInscrito();
        this.getTotalInscritos();
        this.validarInicioEvento();
        this.validarFinEvento();
      });
    },
    getImg() {
      api.get("event/show/img/" + this.data.id).then((response) => {
        // console.log(response.data);
        this.topImg = response.data.top;
        this.leftImg = response.data.left;
        // if (this.leftImg.length > 0) {
        //   return this.leftImg;
        // }

        // this.leftImg[0] = this.data.url_imagen + "no_image.png";
      });
    },
    getTipoInscripcion() {
      // console.log(this.data.id);
      api
        .get("config/show/tipoinscripcion/" + this.data.id)
        .then((response) => {
          this.Inscripcion = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.snackbarMessage = error.response.data.message;
          this.loading = false;
        });
    },
    parseDate(fecha) {
      moment.locale("es");
      let fecha_final = this.separa_fecha(fecha);
      return moment(fecha_final).format("D [de] MMMM [de] YYYY");
    },
    separa_fecha(fecha_hora) {
      if (fecha_hora == null) return;

      const fechaSeparada = fecha_hora.split("T");
      const fechaFinal = fechaSeparada[0];

      return fechaFinal;
    },

    getDepartamentos() {
      api.get("event/show/deptos").then((response) => {
        this.deptos = response.data.data;
        // console.log(response.data.data);
      });
    },
    getTotalInscritos() {
      api.get("event/show/total/inscritos/" + this.data.id).then((response) => {
        this.totalInscritos = response.data;
        // console.log(this.totalInscritos);
      });
    },
    // save() {
    //   this.editedItem.departamento_id = this.selectDepto.id;
    //   this.editedItem.sexo = this.selectSexo.id;
    //   this.editedItem.evento_id = this.data.id;
    //   this.editedItem.inscripcion_id = this.selectInscripcion.id;

    //   if (this.$refs.form.validate()) {
    //     this.loading = true;
    //     api
    //       .post("event/signup/cliente", this.editedItem)
    //       .then((response) => {
    //         // console.log(response.data);
    //         let cliente_id = response.data.id;
    //         this.snackbar = true;
    //         this.color = "primary";
    //         this.snackbarMessage = response.data.message;
    //         this.payment(cliente_id);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.snackbar = true;
    //         this.color = "error";
    //         this.snackbarMessage = error.response.data.message;
    //         this.loading = false;
    //       });
    //   }
    //   // console.log(this.editedItem);
    // },
    save() {
      const newUser = {
        evento_id: this.data.id,
        cliente_id: localStorage.getItem("id"),
        tipo_inscripcion_id: this.selectInscripcion.id,
        talla: this.selectTalla.talla,
        pagado: this.selectInscripcion.precio > 0 ? 0 : 1,
      };
      if (this.$refs.form.validate()) {
        this.loading = true;
        // console.log(this.selectInscripcion);
        api
          .post("cliente/inscripcion/store", newUser)
          .then((response) => {
            this.snackbar = true;
            this.color = "primary";
            if (this.selectInscripcion.precio <= 0) {
              this.snackbarMessage = "Registro realizado correctamente";
              setTimeout(() => {
                let targetURL = "https://inscribeteenlinea.com/";
                let newURL = document.createElement("a");
                newURL.href = targetURL;
                document.body.appendChild(newURL);
                newURL.click();
              }, 300);
              return;
            }
            this.snackbarMessage = response.data.message;
            this.payment(
              newUser.cliente_id,
              newUser.evento_id,
              this.data.costo_plataforma
            );

            // console.log(newUser.cliente_id, newUser.evento_id);
          })
          .catch((error) => {
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.snackbar = true;
              this.color = "error";
              this.snackbarMessage =
                "Tu sesión ha finalizado. Por favor, vuelve a iniciar sesión para continuar";
              this.loading = false;
              localStorage.removeItem("auth_token");
              localStorage.removeItem("full_name");
              localStorage.removeItem("id");
              this.id = null;
            }
          });
      }
    },
    verificarInscrito() {
      api
        .get("cliente/user")
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      const datosUser = {
        evento_id: this.data.id,
        cliente_id: localStorage.getItem("id"),
      };

      api
        .post("cliente/inscripcion/pagado", datosUser)
        .then((response) => {
          // console.log(response.data);
          this.pagado = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    irAInicioSesion() {
      this.$router.push("/micuenta");
    },
    payment(cliente_id, evento_id, costo_plataforma) {
      const paymentLinkDTO = {
        orderName: this.data.nombre_evento,
        orderDescription: this.data.nombre_evento,
        // inicia linea de items
        // lineItems: [
        //   {
        //     product: {
        //       name: this.data.nombre_evento,
        //       price: 25,
        //     },
        //     quantity: 1,
        //   },
        //   {
        //     product: {
        //       name: "Comisión por uso de plataforma",
        //       price: 2,
        //     },
        //     quantity: 1,
        //   },
        // ],
        // finaliza linea de items
        amount:
          parseFloat(this.selectInscripcion.precio) +
          parseFloat(costo_plataforma),
        successUrl: "https://inscribeteenlinea.com/mostrarinfo/" + this.slug,
        cancelUrl: "https://inscribeteenlinea.com/mostrarinfo/" + this.slug,
        metadata: [
          {
            name: "clienteId",
            value: cliente_id.toString(),
          },
          /** valores para conocer la inscripción en el resultado del webhook */
          {
            name: "eventoId",
            value: evento_id.toString(),
          },
        ],
      };

      // URL del endpoint
      const endpointURL = "https://api-pay.n1co.shop/api/paymentlink/checkout";

      // Token de autorización (sustituir por el valor correcto)
      const authToken =
        "sk_25e2ba3193a907fdbee5875af537ed329660511855db891b18a69927fc22de07";

      // Realizar la solicitud POST utilizando fetch
      fetch(endpointURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paymentLinkDTO),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Respuesta:", data);
          // router.push(data.paymentLinkUrl);
          let targetURL = data.paymentLinkUrl;
          let newURL = document.createElement("a");
          newURL.href = targetURL;
          document.body.appendChild(newURL);
          newURL.click();
        })
        .catch((error) =>
          console.error("Error al realizar la solicitud:", error.message)
        );
    },
    terminos() {
      // this.dialog = true;
      // this.generarPdf();
      router.push("http://localhost:8080/docto/carta.pdf");
    },
    generarPdf() {
      this.createObject();

      document.getElementById("output").data =
        "http://localhost:8080/docto/carta.pdf";
    },
    totalBarra() {
      let total = this.totalInscritos * 100;
      let total_barra = total / this.data.limite_inscripcion;
      total_barra = Math.trunc(total_barra);
      // console.log(total_barra);
      return total_barra;
    },
    validarInicioEvento() {
      // moment.locale("es");
      // let fechaActual = moment();
      // let fechaInicio = moment(
      //   this.data.fecha_hora_apertura,
      //   "DD-MM-YYYY HH:mm"
      // );
      // if (fechaActual.isAfter(fechaInicio, "day")) {
      //   console.log("Actual: " + fechaActual);
      //   return true;
      // }
      // console.log("No Actual: " + fechaInicio);
      // return false;
      let fechaActual = new Date();
      let fechaApertura = new Date(this.data.fecha_hora_apertura);
      // console.log(fechaApertura);
      if (fechaActual.getTime() > fechaApertura.getTime()) {
        // console.log("verdadero");
        return true;
      }

      // console.log("falso", fechaActual.getTime(), fechaApertura.getTime());
      return false;
    },
    validarFinEvento() {
      // moment.locale("es");
      // let fechaActual = moment();
      // let fechaFin = moment(this.data.fecha_hora_fin, "DD-MM-YYYY HH:mm");

      // if (fechaActual.isSameOrAfter(fechaFin, "day")) {
      //   console.log("verdadero", fechaFin);
      //   return true;
      // }
      // console.log("falso", fechaFin);
      // return false;
      let fechaActual = new Date();
      let fechaFin = new Date(this.data.fecha_hora_fin);
      if (fechaActual.getTime() > fechaFin.getTime()) {
        // console.log("verdadero");
        return true;
      }
      // console.log("falso--", fechaActual.getTime(), fechaFin.getTime());
      return false;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
#detalles {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #72c1e9;
}

a {
  color: black;
}

a:hover {
  color: red;
}

a:active {
  color: blue;
}
</style>
