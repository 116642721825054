import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Nosotros from '../components/Nosotros.vue'
import Servicios from '../components/Servicios.vue'
import Contacto from '../components/Contacto.vue'
import MostrarInfo from '../components/MostrarInfo.vue'
import MostrarPago from '../components/MostrarPago.vue'
import MiCuenta from '../components/MiCuenta.vue'
import RecuperarClave from '../components/RecuperarContrasena.vue'
import ClaveNueva from '../components/ClaveNueva.vue'

Vue.use(VueRouter)
 /* eslint-disable */

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component:Nosotros,
  },
  {
    path:'/servicios',
    name: 'Servicios',
    component:Servicios,
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component:Contacto,
  },
  {
    path: '/mostrarinfo/:slug',
    name: 'MostrarInfo',
    component:MostrarInfo,
  },
  {
    path: '/mostrarpago/:cliente_id/:evento_id',
    name: 'MostrarPago',
    component:MostrarPago,
  },
  {
    path: '/micuenta',
    name: 'Micuenta',
    component:MiCuenta,
  },
  {
    path: '/recuperarclave',
    name: 'Recuperarclave',
    component:RecuperarClave,
  },
  {
    path: '/recuperarclave/:error',
    name: 'Recuperarclave',
    component:RecuperarClave,
  },
  {
    path: "/nuevaclave/:email/:token",
    name: 'clavenueva',
    component:ClaveNueva,
  },
  // {
  //   path: '*',
  //   component:MostrarInfo,
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
