<template>
    <v-container class="mt-10">

        <div class="mt-6 mb-3 text-center">
            <h1>Contacto</h1>
        </div>

        <v-row align="center" justify="center">

            <div class="col-md-12">

                <p>
                    A continuación todos nuestros medios de contacto.
                </p>

            </div>


        </v-row>

    </v-container>
</template>
 
<script>
export default {
    name: 'IntroduccionContacto',

}
</script>