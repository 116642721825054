<template>
  <v-card dark class="mx-auto" color="#13597c">
    <!-- <v-img
      src="/img/inline5.png"
      class="mx-4 my-4"
      height="50"
      width="100"
    ></v-img> -->
    <!-- <v-card class="mx-auto" color="#13597c" height="100" dark>
      <v-card-text>
      </v-card-text> -->
    <!-- <v-toolbar
        ><v-btn
          to="/"
          class="text-capitalize mr-2"
          v-bind:class="{ 'v-btn--active': $route.path == '/' }"
          v-show="$vuetify.breakpoint.lgOnly"
          color="#f1ba0c"
        >
          Home
        </v-btn></v-toolbar
      > -->
    <!-- </v-card> -->
    <v-toolbar color="#13597c">
      <v-app-bar-nav-icon
        @click="drawer = true"
        v-if="$vuetify.breakpoint.xsOnly"
      >
      </v-app-bar-nav-icon>

      <!-- <v-row>
      <v-col cols="6" class="d-flex justify-center align-center"> -->
      <v-toolbar-title
        :class="{
          '': $vuetify.breakpoint.smAndDown,
          'ml-6': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <router-link class="mb-2" to="/">
          <v-img src="/img/inline6.png" width="200" contain></v-img>
        </router-link>
        <!-- Inscribeteenlinea.com -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn
          to="/"
          class="text-capitalize mr-2"
          v-bind:class="{ 'v-btn--active': $route.path == '/' }"
          v-show="$vuetify.breakpoint.lgOnly"
          text
          color="#13597c"
        >
          <v-icon left dark>mdi-home</v-icon>
          Home
        </v-btn>

        <v-btn
          to="/micuenta"
          class="text-capitalize mr-2"
          v-bind:class="{ 'v-btn--active': $route.path == '/micuenta' }"
          v-show="$vuetify.breakpoint.lgOnly"
          text
          color="#13597c"
        >
          <v-icon left dark>mdi-account</v-icon>
          Mi Cuenta
        </v-btn>
        <v-btn
          to="/contacto"
          class="text-capitalize mr-2"
          v-bind:class="{ 'v-btn--active': $route.path == '/contacto' }"
          v-show="$vuetify.breakpoint.lgOnly"
          color="#13597c"
          text
        >
          <v-icon left dark>mdi-home</v-icon>
          Contacto
        </v-btn>
      </v-toolbar-items>
      <!-- </v-col>
      </v-row> -->

      <!-- <v-btn
        to="/"
        class="text-capitalize mr-2"
        v-bind:class="{ 'v-btn--active': $route.path == '/' }"
        v-show="$vuetify.breakpoint.lgOnly"
        flat
        dark
      >
        Home
      </v-btn> -->

      <!-- <v-btn
        to="/nosotros"
        class="text-capitalize mr-2"
        v-bind:class="{ 'v-btn--active': $route.path == '/nosotros' }"
        v-show="$vuetify.breakpoint.lgOnly"
        color="#f1ba0c"
      >
        Nosotros
      </v-btn> -->

      <!-- <v-btn
        to="/servicios"
        class="text-capitalize mr-2"
        v-bind:class="{ 'v-btn--active': $route.path == '/servicios' }"
        v-show="$vuetify.breakpoint.lgOnly"
        color="#f1ba0c"
      >
        Servicios
      </v-btn> -->

      <!-- <v-btn
        to="/contacto"
        class="text-capitalize mr-2"
        v-bind:class="{ 'v-btn--active': $route.path == '/contacto' }"
        v-show="$vuetify.breakpoint.lgOnly"
        color="#f1ba0c"
      >
        Contacto
      </v-btn> -->

      <!-- <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
 
            <v-btn icon>
              <v-icon>mdi-heart</v-icon>
            </v-btn>
 
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn> -->
      <avatar v-if="full_name" />
    </v-toolbar>

    <v-navigation-drawer
      app
      fixed
      v-model="drawer"
      absolute
      width="100%"
      height="auto"
      temporary
      color="#13597c"
      dark
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item v-bind:class="{ 'bg-active': $route.path == '/' }">
            <v-list-item-icon>
              <v-icon color="#f1ba0c">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/" class="flm">Home</router-link>
            </v-list-item-title>
          </v-list-item>
          <!-- FIXME:Corregir -->
          <v-list-item
            v-bind:class="{ 'bg-active': $route.path == '/micuenta' }"
          >
            <v-list-item-icon>
              <v-icon color="#f1ba0c">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/micuenta" class="flm">Mi Cuenta</router-link>
            </v-list-item-title>
          </v-list-item>

          <!-- <v-list-item
            v-bind:class="{ 'bg-active': $route.path == '/servicios' }"
          >
            <v-list-item-icon>
              <v-icon color="#f1ba0c">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/servicios" class="flm">Servicios</router-link>
            </v-list-item-title>
          </v-list-item> -->

          <v-list-item
            v-bind:class="{ 'bg-active': $route.path == '/contacto' }"
          >
            <v-list-item-icon>
              <v-icon color="#f1ba0c">mdi-earth</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/contacto" class="flm">Contacto</router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import Avatar from "./Avatar.vue";
export default {
  name: "Menu",
  components: {
    Avatar,
  },
  data: () => ({
    full_name: localStorage.getItem("full_name"),
    drawer: false,
    group: null,
    items: [{ title: "Misión" }, { title: "Objetivo" }, { title: "Visión" }],
  }),

  methods: {
    menuItems() {
      return this.menu;
    },
    mounted() {
      // console.log(this.full_name);
    },
  },
};
</script>
<style scope>
.bgsel:hover {
  background: #e0e6e6;
}

.flm:link {
  text-decoration: none;
}

.v-application a {
  color: #ffffff !important;
}
</style>
